import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { Device } from '@ionic-native/device/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Storage } from '@ionic/storage-angular';

@Component({
  selector: 'app-new-release-available-modal',
  templateUrl: './new-release-available-modal.component.html',
  styleUrls: ['./new-release-available-modal.component.scss'],
})
export class NewReleaseAvailableModalComponent implements OnInit {
  @ViewChild(IonModal) modalRelease: IonModal;
  @Input() newVersion: any;
  deviceType: any;
  canDismiss = false;
  constructor(
    private device: Device,
    private inAppBrowser: InAppBrowser,
    private storage: Storage
  ) { }

  ngOnInit() {
    // this.detectDeviceType();
   
  }

  public openAppStore() {
    let appStoreUri: string;


    const platform = this.device.platform;

    switch (platform) {
      case 'iOS':
        const appID = 'id6466608023';
        appStoreUri = `https://apps.apple.com/app/id${appID}`;
        break;

      case 'Android':
        const appPackageName = 'io.bcom.starter';
        appStoreUri = `market://details?id=${appPackageName}`;
        break;

      default:
        break;
    }


    const target = '_system'; // Open the link in the system's default browser
    const options = 'location=yes,hidden=no,clearcache=yes,clearsessioncache=yes';

    this.inAppBrowser.create(appStoreUri, target, options);
  }



  public openModalRelease(newVersion) {
    this.newVersion = newVersion;
    
    if (this.newVersion) {      
        this.canDismiss = this.newVersion.version.canDismiss;
    }
   
    this.modalRelease.present();
  }

  public updateLater() {
    this.storage.set('v', this.newVersion.version.value);
    this.modalRelease.dismiss();
  }




}
