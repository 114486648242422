import { Component, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Platform, IonModal } from '@ionic/angular';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import isOnline from 'is-online';
// import { Badge } from '@awesome-cordova-plugins/badge/ngx';
// import { Badge } from '@ionic-native/badge';
import { Badge } from '@capawesome/capacitor-badge';
import { StorageObservableService } from './services/storage-observable.service';
// import { SplashScreen } from '@capacitor/splash-screen';
import { FadeIn } from './animation/fade';
import { AppCommonListenerService } from './services/app-common-listener.service';
import { NewReleaseAvailableModalComponent } from './components/new-release-available-modal/new-release-available-modal.component';
import { appVersion } from '../environments/app-params';
import { HelperService } from './services/helper.service';
import { Storage } from '@ionic/storage-angular';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: FadeIn
})
export class AppComponent {
  @ViewChild('newReleaseAvailableModalCpt') newReleaseAvailableModalCpt: NewReleaseAvailableModalComponent;
  @ViewChild(IonModal) connectionLostModal: IonModal;

  /**
   * This following code will disable the document right click
   */

  // @HostListener('document:contextmenu', ['$event'])
  // onRightClick(event: Event): void {
  //   event.preventDefault(); 
  // }
  isOffline = false;
  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;
  public subscriptions: Subscription[] = [];
  public connectionStatusMessage: string;
  public connectionStatus: string;
  appVersion = appVersion;
  newVersion: any;

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private platform: Platform,
    private _snackBar: MatSnackBar,
    // private badge: Badge,
    private storageObservableService: StorageObservableService,
    private appCommonListenerService: AppCommonListenerService,
    private helperService: HelperService,
    private storage: Storage
  ) {
    this.initLanguage();
    // this.initializeApp();
    this.listenNotification();

    

    this.platform.ready().then(async () => {

      // await SplashScreen.hide();

      const set = async (count: number) => {
        await Badge.set({ count });
      };
  
      // set(10);
      
      this.platform.resume.subscribe(() => {
      });

      this.platform.pause.subscribe(() => {
      });
    });

  }


  async ngOnInit() {


    this.checkConnection();
    const online = await isOnline();

    this.isOffline = !online;

    if (this.isOffline === true) {
      this.connectionLostModal.present();
    }



    
    /*await SplashScreen.show({
      showDuration: 2000,
      autoHide: true,
    });*/


    
    this.getAppCommonListener();
  }



  private getAppCommonListener() {
    this.appCommonListenerService.getAppCommonListener()
      .subscribe((data) => {
        this.newVersion = data;
        
        if (this.appVersion !== data.version.value) {
          const platform = this.helperService.getPlatform();
          if (platform === 'mobile') {
            this.storage.get('v').then(v => {
              if (v && (v === data.version.value)) {
                return
              } else {
                this.newReleaseAvailableModalCpt.openModalRelease(this.newVersion);
              }
              
            })
           
          }

        }
      })
  }



  private listenNotification() {
    this.storageObservableService.getNotification()
      .subscribe((val: any) => {

        if (val) {
          // this.badge.set(val.count);
        } else {

          // this.badge.clear();
        }
      })
  }


  private initLanguage() {
    let currentLang = this.cookieService.get('lang');
    if (!currentLang) {
      var userLang = navigator.language;
      userLang = userLang.split('-')[0];
      currentLang = userLang;
      this.cookieService.set('lang', currentLang, 0, '/');
    }

    this.translateService.use(currentLang);
  }


  // initializeApp() {
  //   SplashScreen.show({
  //     autoHide: false,
  //     showDuration: 3000
  //   });

  // }

  checkConnection() {

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');



    this.subscriptions.push(this.onlineEvent.subscribe(event => {
      this._snackBar.openFromComponent(SnackbarComponent, { duration: 3000, horizontalPosition: 'left', data: { message: this.translateService.instant('common.Your internet connection was restored'), icon: "fa-wifi", color: 'green' } })
      this.isOffline = false;
      this.connectionStatusMessage = 'Connected to internet! You are online';
      this.connectionStatus = 'online';
      this.connectionLostModal.dismiss();
    }));
    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.isOffline = true;
      this.connectionLostModal.present();
      this.connectionStatusMessage = 'Connection lost! You are offline';
      this.connectionStatus = 'offline';
    }));
  }


}
