import { Media } from '../models/media.model';
import { Member } from '../models/member.model';
import { Account } from '../models/account.model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, from } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Post } from '../models/post.model';
import firebase from 'firebase/app';

interface IResizeImageOptions {
  maxSize: number;
  file: File;
}


@Injectable({
  providedIn: 'root'
})
export class MediaService {
  mediaUrl = environment.app.mediaUrl;
  countryCode = environment.app.countryCode;
  collectionMedias = 'medias';

  constructor(
    private afs: AngularFirestore
  ) {

  }

  public generateUrl(m: Member | Account) {

    const dir = 'profil';
    const path = `${this.mediaUrl}${m.bcomName}%2F${dir}%2F${m.photo}?alt=media&token=32630679-6da1-459b-80be-07716be163f4`;    
    return `url(${path})`;
  }

  public generateUrlThumb(m: any, size?: any) {
    const photo = m.ph || m.photo || m.p;
    if (photo !== '' && photo !== undefined) {
      if (photo.indexOf('facebook') >= 0) {
        return photo;
      } else {
        const dir = (size) ? `thumb@${size}_${photo}` : photo;
        const path = `${this.mediaUrl}${m.bcomName}/profil/${dir}?authuser=4`;
        return `${path}`;
      }
    }
  }

  public generatePostUrl(n: Post, size?: any) {

    const photo = n.photos![0];
    const x = (size) ? `thumb@${size}_${photo}` : photo;
    const path = `${this.mediaUrl}${n.bcomName}%2Fpost%2F${n.topic}%2F${x}?alt=media&token=32630679-6da1-459b-80be-07716be163f4`;
    return `${path}`;
  }

  public imagePath(bcomName: string, type: string, filename: string, size?: number | string) {
    const file = (size) ? `thumb@${size}_${filename}` : filename;
    const path = `${this.mediaUrl}${bcomName}%2F${type}%2F${file}`;

    return `${path}`;
  }

  public generateImageUrl(m: Member) {
    const path = `${this.mediaUrl}${m.bcomName}%2Fprofil%2F${m.photo}?alt=media&token=32630679-6da1-459b-80be-07716be163f4`;
    return path;
  }

  public getUrlStructurePicture(bcomName: string, filename: string) {
    const path = `${this.mediaUrl}${bcomName}%2Fstructures%2F${filename}?alt=media&token=32630679-6da1-459b-80be-07716be163f4`;
    return `${path}`;
  }

  public getUrlLogo(bcomName: string) {
    return `${this.mediaUrl}${this.countryCode}%2F${bcomName}%2Flogo.png?alt=media&token=6479318e-3aba-4427-b7ea-adfcfa21da98`;
  }

  public getMedias(bcomName: string, structureName?: string, paramsId?: string): Observable<firebase.firestore.QuerySnapshot> {
    return this.afs.collection(this.collectionMedias)
      .doc(bcomName)
      .collection('values', ref => {
        return ref.where('structure.key', '==', structureName)
          .where('structure.name', '==', paramsId);
      })
      .get();
  }

  public saveMedia(bcomName: string, media: Media): Observable<firebase.firestore.DocumentReference> {
    return from(this.afs.collection(this.collectionMedias)
      .doc(bcomName)
      .collection('values')
      .add(media));
  }


  public resizeImage(settings: IResizeImageOptions) {

    const file = settings.file;
    const maxSize = settings.maxSize;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');
    const dataURItoBlob = (dataURI: string) => {
      const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
        atob(dataURI.split(',')[1]) :
        unescape(dataURI.split(',')[1]);
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
    };
    const resize = () => {
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);
      let dataUrl = canvas.toDataURL('image/jpeg');
      return dataURItoBlob(dataUrl);
    };

    return new Promise((ok, no) => {
      if (!file.type.match(/image.*/)) {
        no(new Error("Not an image"));
        return;
      }

      reader.onload = (readerEvent: any) => {
        image.onload = () => ok(resize());
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    })
  };

}
