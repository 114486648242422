
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/auth';
import { CryptoService, sessionUserInfoKey } from './crypto.service';
import { StorageService } from '../storage.service';


@Injectable({
  providedIn: 'root'
})
export class AppCheck implements CanActivate {


  constructor(
    private router: Router,
    private storageService: StorageService

  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {   
      this.storageService.getObject(sessionUserInfoKey)
        .then(val => {
           if (val) {
             this.router.navigateByUrl('/')
           } else {
             resolve(true);
           }
          
        })
    });
  }


}
