import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';

export const sessionUserInfoKey = 'sui';


@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  // s = environment.app.s;
  s: string;
  cookie = environment.app.cookie;
  constructor(
    private cookieService: CookieService,
    private storage: Storage
  ) {


  }

  async encodeObject(o: any) {


    try {
      this.s = await this.storage.get('access_token');

      if (this.s) {
        const encryptedData = CryptoJS.AES.encrypt(o, this.s).toString();
        return encryptedData;
      } 
        
    } catch (error) {
      throw error;
    }

  }

  async encodeAndSaveObject(cookieKey: string, o: any) {
    this.s = await this.storage.get('access_token');
    const cp = Object.assign({}, o);
    const encode = CryptoJS.AES.encrypt(JSON.stringify(cp), this.s).toString();

    this.cookieService.set(cookieKey, encode, this.cookie.expires, this.cookie.path);

  }


  async decodeObject(val: any) {
    this.s = await this.storage.get('access_token');
        
    if (this.s && val) {
      const bytes = CryptoJS.AES.decrypt(val, this.s);
      const originalText: any = bytes.toString(CryptoJS.enc.Utf8);      

      try {
        const res = JSON.parse(originalText);
        return res;
      } catch (error) {
        return null;
      }
    }
  }

  public decodeObjectByKey(key: string) {
    let val = this.cookieService.get(key);

    const bytes = CryptoJS.AES.decrypt(val, this.s);
    const originalText: any = bytes.toString(CryptoJS.enc.Utf8);
    try {
      const res = JSON.parse(originalText);
      return res;
    } catch (error) {
      return null;
    }


  }

  public getSessionUserInfo() {
    return this.decodeObjectByKey(sessionUserInfoKey);
  }

  public getLocalPlanSetting() {
    return this.decodeObjectByKey('plan');
  }

}