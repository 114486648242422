import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { ModalAlertSecutiryComponent } from '../components/modal-alert-secutiry/modal-alert-secutiry.component';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    constructor(
        private modalCtrl: ModalController
    ) {

    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(async event => {
                if (event instanceof HttpResponse) {
                    const body = event.body;
                    if (body && body.message === 'forbidden') {

                            const modal = await this.modalCtrl.create({
                                component: ModalAlertSecutiryComponent,
                                canDismiss: true,
                            });
                            await modal.present();

                    }
                }
            })
        );
    }
}
