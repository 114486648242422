
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/auth';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage-angular';


firebase.initializeApp(environment.firebase);

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  loggedSubject: Subject<any> = new Subject();
  dateOk = false;
  isProduction = environment.production;
  countryCode = environment.app.countryCode;

  constructor(
    private router: Router,
    private storage: Storage
  ) {
  }
  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
           
        
        if (user) {
          
          if (window.location.pathname.indexOf('signup') < 0 && window.location.pathname.indexOf('signin') < 0) {
            resolve(true);
          }
          resolve(true);


        } else {
          this.storage.clear();
          this.router.navigateByUrl('/o/login'); 
          resolve(false);
        }
      }, (error) => {
        console.log('Firebase Auth error: ', error);
      });
    });
  }


}
