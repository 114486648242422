import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import AgoraRTC from "agora-rtc-sdk-ng";
import { IAgoraRTCRemoteUser, ILocalVideoTrack, ILocalAudioTrack } from 'agora-rtc-sdk-ng';

@Component({
  selector: 'app-live-streaming',
  templateUrl: './live-streaming.component.html',
  styleUrls: ['./live-streaming.component.scss'],
})
export class LiveStreamingComponent implements OnInit, OnDestroy {
  isManager: boolean;
  private client = AgoraRTC.createClient({ mode: 'live', codec: 'h264' });
  private localVideoTrack: ILocalVideoTrack;
  private localAudioTrack: ILocalAudioTrack;
  private APP_ID = 'd687c2638a7f471693e8db9218dfb0a8';
  private TOKEN = '007eJxTYLj6424yt191v2HBr57btxwVJk7VV52up3HMa3l2UijnoXUKDClmFubJRmbGFonmaSbmhmaWxqkWKUmWRoYWKWlJBokWfocnpzUEMjIU83oyMEIhiC/O4JScn6tQklpcolCWmZKar+CckZiXl5rDwAAAyEElUQ==';
  private CHANNEL_NAME = 'Bcom test video Channel';

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    const x = this.getParameter('isManager');
    this.isManager = (/true/).test(x);
    if (this.isManager) {
      this.initializeAgoraClient();
    }
    
  }

  
  async initializeAgoraClient() {
    console.log('Agora initilize :', this.client.connectionState);

    try {
      if (this.client.connectionState === 'DISCONNECTED') {
        await this.client.join(this.APP_ID, this.CHANNEL_NAME, this.TOKEN);
        if (!this.isManager) {
          this.client.on('user-published', async (user: IAgoraRTCRemoteUser, mediaType: 'video' | 'audio') => {
            await this.client.subscribe(user, mediaType);
            if (mediaType === 'video') {
              const videoContainer = document.getElementById('remote-video');
              user.videoTrack.play(videoContainer);
            }
            if (mediaType === 'audio') {
              user.audioTrack.play();
            }
          });
        }
      } else {
        console.warn('Agora: Client already in connecting/connected state');
      }
    } catch (error) {
      console.error('Agora: Failed to initialize Agora client', error);
    }
  }

  async startLiveStream() {
    console.log('Agora: startLiveStream ', this.client.connectionState);
    
    
    if (this.client.connectionState === 'CONNECTED') {
      try {
        if (this.isManager && !this.localVideoTrack && !this.localAudioTrack) {
          [this.localAudioTrack, this.localVideoTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
          const videoContainer = document.getElementById('local-video');
          this.localVideoTrack.play(videoContainer);
          await this.client.publish([this.localAudioTrack, this.localVideoTrack]);
        }
      } catch (error) {
        console.error('Failed to start live stream', error);
      }
    } else {
      console.warn('Client not connected yet');
    }
  }

  async stopLiveStream() {
    this.localAudioTrack.close();
    this.localVideoTrack.close();
    await this.client.leave();
  }

  public getParameter(param: string) {
    return this.route.snapshot.paramMap.get(param);
  }

  ngOnDestroy(): void {
    if (this.localAudioTrack) {
      this.localAudioTrack.close();
    }
    if (this.localVideoTrack) {
      this.localVideoTrack.close();
    }
    this.client.leave();
  }


}
