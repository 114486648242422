import { appVersion } from './app-params';

export const environment = {
    production: true,
    debug: false,
    firebase: {
        apiKey: "AIzaSyCuzdEYKm_zZ8lhYKZ5EamiBv3wn2KteH0",
        authDomain: "mu-bcom-group.firebaseapp.com",
        projectId: "mu-bcom-group",
        storageBucket: "mu-bcom-group.appspot.com",
        messagingSenderId: "891488508487",
        appId: "1:891488508487:web:2a4f6936e9da35ede76f62",
        measurementId: "G-RVRFJB4GTJ",
        vapidKey: "BPvTAUNVVfdjB4plIeuHPWje5XP_FLioXpQe-uqA8eGCfYMe42dG2NUmkmo7WoKX2MAsIwmx4ey_qlHEPb2CwNk",
    },
    app: {
      version: appVersion,
      paginationLimit: 20,
      categoryDeep: 20,
      API: 'https://us-central1-mu-bcom-group.cloudfunctions.net/API',
      mediaUrl: 'https://storage.googleapis.com/mu-bcom-group.appspot.com/',
      mediaPublic: 'https://storage.googleapis.com/mu-bcom-public',
      mediaUrlToken: 'https://firebasestorage.googleapis.com/v0/b/mu-bcom-group.appspot.com/o/',
      mediaToken: '88d839ae-63e0-473f-a4da-a9454b0e8e28',
      customDomain: 'https://us-central1-mur-bcom-group.cloudfunctions.net/API',
      fbAppId: '712449625883999',
      countryCode: "mu",
      flag: "mur",
      currency: "MUR",
      country: "Mauritius",
      callingCode: '+230',
      mobilePhoneLength: 8,
      cookie: {
        path: '/',
        expires: 14, // (Day)
      }
    }
  };
  