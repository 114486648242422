import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Account } from '../models/account.model';
import firebase from 'firebase/app'; import 'firebase/auth';
import { SessionUserInfo } from '../models/session-userinfo.model';
import { MediaService } from './media.service';
import { StorageService } from '../storage.service';
// import { Badge } from '@ionic-native/badge';
import { StorageObservableService } from './storage-observable.service';
import { throwError, from } from 'rxjs';
import { AppUserStateService } from './app-user-state.service';
import { mergeMap } from 'rxjs/operators';
import { LoadingController, Platform } from '@ionic/angular';

// import { Facebook } from '@ionic-native/facebook';


export const month = {
  '0': 'Jan',
  '1': 'Feb',
  '2': 'Mar',
  '3': 'Apr',
  '4': 'May',
  '5': 'June',
  '6': 'July',
  '7': 'Aug',
  '8': 'Sept',
  '9': 'Oct',
  '10': 'Nov',
  '11': 'Dec',
}

declare var jQuery: any;
@Injectable({
  providedIn: 'root'
})
export class HelperService {
  mediaUrl = environment.app.mediaUrl;
  countryCode = environment.app.countryCode;
  cookie = environment.app.cookie;
  loadingIn: any;

  constructor(
    private storageService: StorageService,
    private mediaService: MediaService,
    private storageObservableService: StorageObservableService,
    private appUserStateService: AppUserStateService,
    private loadingController: LoadingController,
    private platform: Platform
    // private fb: Facebook
  ) {

    this.init();
  }

  async init() {
    this.loadingIn = await this.loadingController.create({
      cssClass: 'default-loading',
      spinner: 'crescent'
    });
  }

  public sansAccent(str: string, separator: string) {
    const accent = [
      /[\300-\306]/g, /[\340-\346]/g, // A, a
      /[\310-\313]/g, /[\350-\353]/g, // E, e
      /[\314-\317]/g, /[\354-\357]/g, // I, i
      /[\322-\330]/g, /[\362-\370]/g, // O, o
      /[\331-\334]/g, /[\371-\374]/g, // U, u
      /[\321]/g, /[\361]/g, // N, n
      /[\307]/g, /[\347]/g, // C, c
    ];
    const noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

    for (let i = 0; i < accent.length; i++) {
      str = str.replace(accent[i], noaccent[i]);
    }
    str = str.replace(/[^\w\s]/gi, '');
    str = str.replace(/ /g, separator);
    return str;
  }

  public checkIfNumberIsValid(countryCode: string, str: string): boolean {
    const num = str.replace(/ /g, '');
    const isNum = /^\d+$/.test(num);

    const check = (ln: number) => {
      console.log('// ', ln);

      if (num.length === ln && isNum === true) {
        return true;
      } else {
        return false;
      }
    }

    switch (countryCode) {
      case 'mu':
        check(8);
        break;

      default:
        return false;
    }

  }

  public getTimestampInSeconds() {
    return Math.floor(Date.now() / 1000)
  }

  public retrieveBcomName() {
    if (window.localStorage.getItem('YYZS2k6dQAfeUueZ9tQxdFGtOyW2')) {
      const mock = window.localStorage.getItem('YYZS2k6dQAfeUueZ9tQxdFGtOyW2')!.split('|');
      return mock[1];
    } else {
      return null;
    }
  }

  public compareDate(dt: any) {
    const dateCreated = this.convertTimestampToDate(dt).getTime();
    const now = new Date().getTime();
    return (dateCreated > now) ? true : false;
  }

  public mapDocumentSnapshot<T>(doc: firebase.firestore.DocumentSnapshot | firebase.firestore.DocumentSnapshot<unknown>): T {
    const data: any = doc.data() as any;
    const id = doc.id;
    const res = {
      ...data,
      id
    };
    return res;
  }


  public generateRandomChar(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public generateId(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  public getTodayDate(separator: string, currentLang: string) {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    let todayDate: string;
    switch (currentLang) {
      case 'fr':
        todayDate = dd + `${separator}` + mm + `${separator}` + yyyy;
        break;
      default:
        todayDate = mm + `${separator}` + dd + `${separator}` + yyyy;
        break;
    }
    return todayDate;
  }


  public mapQuerySnapshotDocs<T>(docs: firebase.firestore.QueryDocumentSnapshot<unknown>[]): T[] {
    const result: T[] = docs.map((item: any) => {
      const data = item.data();
      const id = item.id;
      const res = {
        ...data,
        id
      };
      return res;
    });
    return result;
  }

  public convertDate(inputFormat: string) {
    function pad(s: number) { return (s < 10) ? '0' + s : s; }
    const d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
  }



  public formatCurrency(currency: string): string {
    let c: string = '';
    switch (currency) {
      case 'mur':
        c = 'Rs'; break;
      case 'eur':
        c = '€'; break;
      case 'usd':
        c = '$'; break;
    }
    return c;
  }

  public getParameterByName(name: string, url: string): string | null {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  public formatUrlImage(businessName: string, photo: string): string {
    const urlFirebase = `https://firebasestorage.googleapis.com/v0/b/${environment.firebase.storageBucket}/o/`;
    const token = 'b8ca7211-6f8a-4275-9483-294df0878794';
    const url = `${urlFirebase}${businessName}%2F${photo}?alt=media&token=${token}`;
    return url;
  }

  public scrollEvent(parent: any) {
    let position = jQuery(window).scrollTop();
    jQuery(window).scroll((event: any) => {
      const scroll = jQuery(window).scrollTop();

      if (scroll > position) {
        parent.scrollFlag = true;
      } else {
        parent.scrollFlag = false;
      }

      if (scroll > 200) {
        parent.displayUpIcon = true;
      } else {
        parent.displayUpIcon = false;
      }
      position = scroll;
    });
  }

  public convertTimestampToDate(timestamp: any) {
    if (timestamp) {
      const s = timestamp.seconds || timestamp._seconds;
      if (s) {
        const dt = new Date(s * 1000);
        return dt;
      } else {
        return timestamp;
      }
    }

  }

  public fileToBase64(file: any, c: Account) {
    var reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64: any = reader.result;
      console.log(c);
      // this.customerService.setEventCustomer(c);
      return base64;
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  public formatPhoneNumber(phoneNumberString: string) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  public logoUrl(account: Account, size?: any) {
    const x = (size) ? `thumb@${size}_logo.png` : 'logo.png';
    return `${this.mediaUrl}${account.bcomName}/${x}`;
  }

  public convertDateToTimestamp(dt: any) {
    const t = new Date(dt.seconds * 1000);
    return t;
  }

  public orderNumber() {
    var d = new Date();
    var n = d.valueOf();
    return n;
  }

  public addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  public validateEmail(mail: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true
    }
    return false
  }

  async logout(sui: SessionUserInfo) {

    await this.loadingIn.present();

    this.storageObservableService.setNotification(undefined);

    this.appUserStateService.changeUserState(sui, 'paused')
      .pipe(
        mergeMap(() => {

          return from(this.storageService.storage.clear())
        })
      )
      .subscribe(() => {
        this.storageService.clear();
        this.loadingIn.dismiss();
        firebase.auth().signOut();
        // this.fb.logout()
        //   .then(() => {
        //     firebase.auth().signOut();
        //   })  
      }, (error) => {
        console.log('/// ', error);

      })
  }

  async exit() {
    this.storageObservableService.setNotification(undefined);
    this.storageService.storage.clear();
    firebase.auth().signOut();
  }

  public formatImageUrl(sui: SessionUserInfo) {
    return (sui.freshUpdate === false)
      ? `url(${this.mediaService.generateUrlThumb(sui, '128')})`
      : `${this.mediaService.generateUrl(sui)}`;
  }

  public cleanPath(path: any, space?: boolean) {
    if (space) {
      return path.replace(/_/g, ' ').replace(/>/g, ' > ');
    } else {
      return path.replace(/_/g, ' ');
    }

  }

  public capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  get getBgColor() {
    const colors = ["#cf1e25", "#ab398f", "#2054b6", "#5eba7d", "#fff400", "#465e6a", "#fc642a", "#76d6c1", "#fc978a"];
    return colors[Math.floor(Math.random() * colors.length)];
  }

  public compare(a: any, b: any, key: string) {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  }

  public getProfil(c: Account, size?: any) {

    if (c.profilPhotoEmpty === true) {
      return `url(/assets/images/default-profile.jpeg)`;
    } else {
      if (c.profilPhotoBase64) {
        return `url(${c.profilPhotoBase64})`;
      } else {
        if (c.accountType === 'member' || c.accountType === 'guest' || (c.accountType === 'admin' && c.privilege === 'boardMember')) {
          if (c.provider === 'facebook') {
            return `url(${c.photo}?width=${size})`;
          } else {

            return `${this.formatImageUrl(c!)}`;
          }

        } else {
          return `url(${this.logoUrl(c!)})`;
        }

      }

    }

  }

  public validateMauritiusPhoneNumber(phoneNumber: any) {
    const regex = /^\+230[59]\d{7}$/;
    return typeof phoneNumber === "string" && !isNaN(<any>phoneNumber) && regex.test(phoneNumber);
  }

  public displayDateOfBirth(o: any) {
    return `${o.day} ${month[o.month]} ${o.year}`;
  }

  public calculateAge(dateOfBirth) {
    // Convert dateOfBirth to a Date object
    const dob = new Date(dateOfBirth.year, dateOfBirth.month - 1, dateOfBirth.day);

    // Calculate the difference in milliseconds between the current date and dob
    const diff = Date.now() - dob.getTime();

    // Convert the difference to years and return
    return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
  }

  public formattedPhone(phone: string) {
    switch (this.countryCode) {
      case 'mu':
        return phone.slice(0, 4) + ' ' + phone.slice(4, 5) + ' ' + phone.slice(5, 8) + ' ' + phone.slice(8);
      default:
        break;
    }
  }

  public handleError(err) {
    console.log(err);

    return throwError(`OUPS!!!, une erreur s'est produite lors de l'opération`);
  }

  public getPlatform() {
    // Are we on mobile platform? Yes if platform is ios or android, but not desktop or mobileweb, no otherwise
    if (
      this.platform.is('ios')
      || this.platform.is('android')
      && !(this.platform.is('desktop') || this.platform.is('mobileweb'))) {
      return 'mobile';
    } else {
      return 'browser';
    }
  }

  public sortName(array: any[], field: string) {
    array.sort(function (a, b) {
      var nameA = a[field].toUpperCase(); // Convert names to uppercase for case-insensitive sorting
      var nameB = b[field].toUpperCase();

      if (nameA < nameB) {
        return -1; // Name A comes before Name B
      }
      if (nameA > nameB) {
        return 1; // Name A comes after Name B
      }
      return 0; // Names are equal
    });

    return array;
  }

  public extractParamFromUrl(url: string, param: string) {
    // Divisez l'URL en parties en utilisant le caractère '&'
    var parts = url.split('&');

    // Parcourez les parties pour trouver le paramètre 'token'
    for (var i = 0; i < parts.length; i++) {
      var part = parts[i];

      // Si la partie contient 'token=', c'est le paramètre 'token'
      if (part.indexOf(`${param}=`) !== -1) {
        // Divisez la partie en utilisant le caractère '='
        var tokenPart = part.split('=');

        // La deuxième partie (index 1) contient la valeur du token
        return tokenPart[1];
      }
    }

    // Si le paramètre 'token' n'est pas trouvé, renvoyez null ou une valeur par défaut
    return null;
  }

  public generateIdFromText(text: string) {
    return text
      .toLowerCase() // Convert to lowercase
      .trim() // Trim whitespace from both ends
      .replace(/[^\w\s-]/g, '') // Remove all non-word characters (punctuation, spaces, etc.)
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/--+/g, '-'); // Replace multiple hyphens with a single hyphen
  }




}
