import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class StorageObservableService {
    storage$ = new Subject();
    notification$ = new Subject();
    constructor(
    ) {
    }


    public setStorage(x: any) {
        this.storage$.next(x);
    }

    public getStorage() {
        return this.storage$;
    }

    public setNotification(x: any) {
        this.notification$.next(x);
    }

    public getNotification() {
        return this.notification$;
    }

}
