import { Injectable } from '@angular/core';
import { Router, Resolve } from '@angular/router';
import firebase from 'firebase/app';
import 'firebase/auth';

import { Observable, EMPTY, from } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable()
export class CheckUserResolver implements Resolve<any> {
  constructor(
      private router: Router
      ) {}

  resolve(): any {
     
         firebase.auth().onAuthStateChanged((user) => {
            if (user) {

                this.router.navigateByUrl('/')
              
            } else {
              // No user is signed in.
            }
          }
    )
  }
}