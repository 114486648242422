import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { from, Observable, EMPTY, of, throwError } from 'rxjs';
import * as DOMPurify from 'dompurify';
import { mergeMap, catchError } from 'rxjs/operators';
import { SessionUserInfo } from '../models/session-userinfo.model';

export type AppState = 'resumed' | 'paused';

@Injectable({
  providedIn: 'root'
})
export class AppUserStateService {

  constructor(
    private afs: AngularFirestore
  ) { }

  public changeUserState(sui: SessionUserInfo, state: AppState): Observable<any> {
    const lastSigned = new Date();
    const query: any = {
      appState: state
    };
    if (state === 'paused') {
      query['lastSigned'] = lastSigned;
    }


    if (sui && sui.accountType === 'member') {
      return from(this.afs.collection('member-shortcuts')
      .doc(sui.bcomName)
      .collection('list')
      .doc(sui.id)
      .update(query))
      .pipe(
        mergeMap(() => {
          return this.updateDeepField(sui.bcomName, sui.id, sui.path.split('>'), state, lastSigned)
        }),
        mergeMap(() => {
          const updatedAccountData: any = {
            appState: state,
            lastSigned: lastSigned 
          }
          return this.updateRouterAccount(sui, updatedAccountData);
        }),
        catchError((error) => {
          // Handle error here
          console.error('==== ', error);
          return  of([]);; // Rethrow the error or return a new observable
        }),
      )
    }  else {      
      return of([]);
    }  
   
  }


  public updateDeepField(bcomName: string, id: string, aPath: string[], state: AppState, lastSigned: Date) {
    const path = [...aPath];
    if (path.indexOf(bcomName) < 0) {
      path.unshift(bcomName);
    }

    const docRef = `this.afs
    .collection('members')`;
    let condition = 'doc';
    let stringQuery = '';
    for (let index = 0; index < path.length; index++) {
      if (condition === 'doc') {
        stringQuery += `.doc('${path[index]}')`
      } else {
        stringQuery += `.collection('${path[index]}')`
      }

      condition = (condition === 'doc') ? 'collection' : 'doc';

    }

    /**
     * If path impair, all the documents are in the path name collections
     */
    if (path.length % 2 === 1) {
      stringQuery += ".collection('members')";
    }

    

    const query = eval(DOMPurify.sanitize(docRef + stringQuery));
    return query.doc(id)
      .update({ appState: state, lastSigned: lastSigned })

  }

  public updateRouterAccount(sui: SessionUserInfo, updatedAccountData: any): Promise<void> {
    // const docId = (sui.provider === 'email') ? sui.email : sui.
    let docId: string;
    switch (sui.provider) {
      case 'email':
        docId = sui.email;
        break;
      case 'mobileNumber':
        docId = sui.mobileNumber;
      default:
        docId = sui.mobileNumber;
        break;
    }
    const docRef = this.afs.collection('routers').doc(docId);
    
    return docRef.get().toPromise().then((docSnapshot) => {
      if (docSnapshot.exists) {
        const data: any = docSnapshot.data();
        const accounts = data.accounts || [];

        const updatedAccounts = accounts.map(account => {
          if (account.bcomName === sui.bcomName) {
            return { ...account, ...updatedAccountData };
          }
          return account;
        });

        return docRef.update({ accounts: updatedAccounts });
      } else {
        return Promise.reject('Document not found');
      }
    });
  }

}
