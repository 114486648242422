import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { CheckUserResolver } from './services/resolver.service';
import { AppCheck } from './services/app-check.service';
import { LiveStreamingComponent } from './modules/live-streaming/live-streaming.component';

const routes: Routes = [
  {
    path: 'o',
    loadChildren: () => import('./modules/offline/offline.module').then(m => m.OfflineModule),
    canActivate: [ AppCheck ]
  },
  {
    path: '',
    loadChildren: () => import('./modules/app-common/app-common.module').then(m => m.AppCommonModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'activity-history',
    loadChildren: () => import('./modules/activity-history/activity-history.module').then(m => m.ActivityHistoryModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'order',
    loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule)
  },
  {
    path: 'live-streaming/:isManager',
    component: LiveStreamingComponent
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
