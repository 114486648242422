import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { CheckUserResolver } from './services/resolver.service';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NewReleaseAvailableModalComponent } from './components/new-release-available-modal/new-release-available-modal.component';
import { Device } from '@ionic-native/device/ngx';
import { AppLauncher } from '@ionic-native/app-launcher/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { RequestInterceptor } from './services/request-interceptor.service';
import { ResponseInterceptor } from './services/response-interceptor.service';
import { JWT_OPTIONS } from '@auth0/angular-jwt';
import { Storage } from '@ionic/storage-angular';
import { from } from 'rxjs';

// import { Facebook } from '@ionic-native/facebook';
// import { Badge } from '@ionic-native/badge';

// const fb = {
//   name: 'BCOM', 
//   appId: '573237698011176',
//   version: 'v12.0'
// };

registerLocaleData(localeFr);

// export function tokenGetter() {  
//   return localStorage.getItem("access_token");
// }

export function tokenGetter(storage: Storage) {
  return from(storage.get('access_token'));
}


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [AppComponent, NewReleaseAvailableModalComponent],
  imports:
    [
      BrowserAnimationsModule,
      BrowserModule,
      IonicModule.forRoot(),
      MatSnackBarModule,
      AppRoutingModule,
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireAuthModule,
      HttpClientModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      }),
      IonicStorageModule.forRoot(),
      LazyLoadImageModule,
      // JwtModule.forRoot({
      //   config: {
      //     tokenGetter: tokenGetter,
      //     allowedDomains: [
      //       "http://localhost:5001",
      //       "us-central1-dev-forahaspa.cloudfunctions.net",
      //       "https://us-central1-dev-forahaspa.cloudfunctions.net",
      //       "https://mu.community.forahaspa.com",
      //       "https://community.forahaspa.com",
      //       "localhost:5001",
      //       "localhost:4201"
      //     ],
      //     disallowedRoutes: ["http://example.com/examplebadroute/"],
      //   },
      // }),
    ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    CheckUserResolver,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    InAppBrowser,
    Device,
    AppLauncher,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (storage: Storage) => () => storage.create(),
      deps: [Storage],
      multi: true,
    },
    {
      provide: JWT_OPTIONS,
      useValue: {
        tokenGetter: (storage: Storage) => {
          return tokenGetter(storage);
        },
        // ...
      },
    }
    // Facebook,
    // Badge
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
