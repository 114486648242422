import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppCommonListenerService {
  appListener$: Subject<any> = new Subject();
  constructor(
    private afs: AngularFirestore
  ) { }


  public getAppCommonListener() {
    return this.afs.collection('app-common-listener')
            .doc('params')
            .snapshotChanges().pipe(
                map((a) => {
                 
                    const data: any = a.payload.data();                    
                    const id = a.payload.id;
                    const res = { id, ...data };
                    this.appListener$.next(res);
                    return res;
                })
              );
  }
}
