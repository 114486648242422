import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs'; // Importez from depuis rxjs
import { switchMap } from 'rxjs/operators'; // Importez switchMap depuis rxjs/operators
import { Storage } from '@ionic/storage-angular';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(private storage: Storage) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.storage.get('access_token')).pipe(
            switchMap(accessToken => {
                
                const modifiedReq = req.clone({
                    headers: req.headers
                        .set('app', 'bcom')
                        .set('Authorization', `Bearer ${accessToken}`)
                });
                return next.handle(modifiedReq);
            })
        );
    }
}
