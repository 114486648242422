import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../services/helper.service';
import { StorageService } from '../../storage.service';
import { sessionUserInfoKey } from '../../services/crypto.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-alert-secutiry',
  templateUrl: './modal-alert-secutiry.component.html',
  styleUrls: ['./modal-alert-secutiry.component.scss'],
})
export class ModalAlertSecutiryComponent implements OnInit {

  constructor(
    private helperService: HelperService, 
    private storageService: StorageService,
    private modalCtrl: ModalController
    ) { }

  ngOnInit() {}

  public logout() {
    this.storageService.getObject(sessionUserInfoKey)
      .then(data => {
        this.modalCtrl.dismiss();
        this.helperService.logout(data);
      })
  }

}
