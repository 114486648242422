import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { CryptoService } from './services/crypto.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;
  constructor(public storage: Storage, private cryptoService: CryptoService) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;

  }


  // set a key/value
  async set(key: string, value: any): Promise<any> {
    try {
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      } 
      
      await this.storage.set(key, this.cryptoService.encodeObject(value));
      return true;
    } catch (reason) {
      return false;
    }
  }
  // to get a key/value pair
  async get(key: string): Promise<any> {
    try {
      
      const crypte = await this.storage.get(key);      
      const result = this.cryptoService.decodeObject(crypte);      
      
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      return null;
    }
  }
  // set a key/value object
  async setObject(key: string, object: Object) {
    try {      
      await this._storage.set(key, this.cryptoService.encodeObject(JSON.stringify(object)));
      return true;
    } catch (reason) {
      return false;
    }
  }
  // get a key/value object
  async getObject(key: string): Promise<any> {
    try {
      const data = await this._storage.get(key);     
      
       if (typeof data === 'string') {
        const result = this.cryptoService.decodeObject(data);
        if (result != null) {
          if (typeof result === 'object') {
            return result;
          } else {          
            return JSON.parse(result);
          }
          
        }
       } else {
         return data;
       }
     
      return null;
    } catch (reason) {
      return null;
    }
  }
  // remove a single key value:
  remove(key: string) {
    this.storage.remove(key);
  }
  //  delete all data from your application:
  clear() {
    this.storage.clear();
  }
}