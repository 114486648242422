// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import  * as environmentDEV from './environment.dev';
import  * as environmentDEMO from './environment.demo';
import  * as environmentLOCAL from './environment.local';
import  * as environmentLOCAL_MU from './environment.local-MU';
import  * as environmentMU from './environment.mu';
import  * as environmentSC from './environment.sc';
import  * as environmentRE from './environment.re';
import  * as environmentMG from './environment.mg';
import  * as environmentPROD from './environment.prod';



const localCountryENV = environmentMU


export const environment: { 
  production?: boolean,
  debug?: boolean,
  firebase?: any,
  app?: any
} = {
  production: localCountryENV.environment.production, 
  debug: localCountryENV.environment.debug,

  firebase: localCountryENV.environment.firebase,
app: localCountryENV.environment.app,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
